import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { AppConfig } from '../../../environments/environment';
import { Device, LOCAL_STORAGE_TOKEN_KEY, loginResponse, PbxUser, SubscriptionResponse } from '../models';
import { Observable } from 'rxjs';
import { ConfigService, ElectronService, SipJsStoreService } from '../../core/services';
import { AccountInfo, AccountTokens, SessionDTO, SipCredentials } from '../store/states-models';
import { AccountFacade } from '../store/facade';
import { Router } from '@angular/router';
import { IPC_CHANNELS } from '../../../../electron-utils/electron.model';
import { RomulusInboxService } from '../../shared/services/romulus/romulus.service';
import { ChatService } from '../../shared/services/chat/chat.service';




@Injectable({
  providedIn: 'root'
})
export class AccountService {

  constructor(
    private http: HttpClient,
    private configService: ConfigService,
    private accountFacade: AccountFacade,
    private sipStore: SipJsStoreService,
    private electronService: ElectronService,
    private router: Router,
    private romulusInboxService: RomulusInboxService,
    private chatService: ChatService
  ) { }

  /**
   * Backend call to perform login.
   * @argument {string} username Username used for login
   * @argument {string} password Password used for login
   * @returns {Observable<loginResponse>} Returns the http request observable
   */
  public login(username: string, password: string): Observable<loginResponse> {
    const headers = new HttpHeaders()
      .append('Content-Type', 'application/x-www-form-urlencoded');
    const data = `grant_type=password&username=${encodeURIComponent(username)}&password=${encodeURIComponent(password)}`
    return this.http.post<loginResponse>(`${AppConfig.endpointURL}/token`, data, { headers: headers })
  }

  /**
   * Method to retrieve sip credentials and configs from backend
   * @returns {Observable<SipCredentials>} Return the http observable
   */
  public getSipCredentials(): Observable<SipCredentials> {
    const config = this.configService.config;
    const params = {
      device_os: config.temporary_config.os,
      machine_id: config.temporary_config.machine_id
    }
    let headers = new HttpHeaders();
    headers.set('device_os', config.temporary_config.os);
    return this.http.get<SipCredentials>(`${AppConfig.endpointURL}/sipwebcredentials`, {
      headers: headers,
      params: params
    });
  }

  /**
   * Get the subscription of the company by it's ID
   * @param {string} companyId Id of the company.
   * @returns {Observable<SubscriptionResponse>} The subscription of the company
   */
  public getSubscription(companyId: string): Observable<SubscriptionResponse> {
    return this.http.get<SubscriptionResponse>(`${AppConfig.endpointURL}/v1/subscriptions/${companyId}`);
  }

  /**
   * Get the account info of the company
   * @returns {Observable<AccountInfo>} Account info of the company
   */
  public getAccountInfo(): Observable<AccountInfo> {
    return this.http.get<AccountInfo>(`${AppConfig.endpointURL}/accountinfo`);
  }

  /**
   * Get the session
   * @returns {Observable<any>} A session object
   */
  public getSession(username: string, deviceUsername: string): Observable<SessionDTO> {
    return this.http.get<SessionDTO>(`${AppConfig.endpointURL}/v1/desktop-app/session/${username}`, {
      params: {
        device_username: deviceUsername
      }
    });
  }

  /**
   * Perfmors a logout and clean of storage.
   */
  public logout(): void {
    this.accountFacade.logout();
    this.sipStore.userAgentWrapper?.destroy();
    this.removeTokensFromStorage();
    this.romulusInboxService.destroy();
    this.chatService.shutDown();
    this.router.navigate(['/auth/login']);
    if(this.electronService.isElectron){
      this.electronService.ipcRenderer.send(IPC_CHANNELS.CLIENT_LOGIN_STATUS, false);
    }
  }

  /**
   * Retrieve the tokens from the local storage (if present)
   * @returns {AccountTokens} Tokens from local storage
   */
  public retrieveTokensFromStorage(): AccountTokens {
    let tks: AccountTokens = JSON.parse(localStorage.getItem(LOCAL_STORAGE_TOKEN_KEY));
    return tks ? tks : undefined;
  }

  /**
   * Store the tokens inot the local storage
   * @param {AccountTokens} tks Tokens to store
   */
  public storeTokensToStorage(tks: AccountTokens): void {
    if (tks) {
      localStorage.setItem(LOCAL_STORAGE_TOKEN_KEY, JSON.stringify(tks));
    }
  }

  /**
   * Remove the tokens from the local storage
   */
  public removeTokensFromStorage(): void {
    localStorage.removeItem(LOCAL_STORAGE_TOKEN_KEY);
  }

  public getUser(pbxId: number, userId: number): Observable<PbxUser> {
    return this.http.get<PbxUser>(`${AppConfig.endpointURL}/v1/pbxes/${pbxId}/users/${userId}`);
  }

  public getDevices(pbxId: number, userId: number): Observable<Device[]>{
    return this.http.get<Device[]>(`${AppConfig.endpointURL}/v1/pbxes/${pbxId}/users/${userId}/devices`);
  }

  public updateOutboundDid(pbxId: number, userId: number, deviceId: number, number: string): Observable<Device> {
    return this.http.put<Device>(`${AppConfig.endpointURL}/v1/pbxes/${pbxId}/users/${userId}/devices/${deviceId}/number/${number}`, null);
  }
}
