import { Injectable } from '@angular/core';
import { Intercom } from 'ng-intercom';
import { AppConfig } from '../../../../environments/environment';
import { Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ChatService {

  public onHide$: Subject<void> = new Subject();
  public isIntercomBooted: boolean = false;

  constructor(
    private intercom: Intercom
    ) {
    this.intercom.boot({
      app_id: AppConfig.INTERCOM_ID,
      hide_default_launcher: true,
      widget: {
        "activator": "#intercom"
      }
    });
    this.isIntercomBooted = true;

    this.intercom.onHide(() => {
      this.onHide$.next();
    })
  }

  public boot() {
    this.intercom.boot({
      app_id: AppConfig.INTERCOM_ID,
      hide_default_launcher: true,
      widget: {
        "activator": "#intercom"
      }
    });
    this.isIntercomBooted = true;
  }

  public bootWithEmail(email: string) {
    this.intercom.boot({
      app_id: AppConfig.INTERCOM_ID,
      hide_default_launcher: true,
      widget: {
        "activator": "#intercom"
      },
      email: email
    });
    this.isIntercomBooted = true;
  }

  public show() {
    this.intercom.show();
  }

  public hide() {
    this.intercom.hide();
  }

  public shutDown() {
    this.intercom.shutdown();
    this.isIntercomBooted = false;
  }

  public update(data: any) {
    this.intercom.update(data);
  }

  public updateLoggedEmail(email: string) {
    this.update({
      email: email
    });
  }
}